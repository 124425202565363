<template>
<div>
    <div class="service-con">
        <!--s: left-->
        <div class="content-l">
            <div class="box">
                <div class="box-break">
                    <a href="javascript:" @click="onBreak"><img src="../../../assets/break.png"></a>
                </div>
                <h3 class="box-name">REPUTATION MANAGEMENT</h3>
                <h1 class="box-title">{{ data.title }}</h1>
                <h3 class="box-text">{{ data.title2 }}</h3>
                <p class="box-con">
                  {{ data.text }}
                </p>
            </div>
        </div>
        <!--e: left-->
        <!--s: right-->
        <div class="content-r">

            <div class="spread">
                <div class="logo a-bouncein">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 2267.72 2267.72" style="enable-background:new 0 0 2267.72 2267.72;" xml:space="preserve">
                        <g id="图层_2">
                            <path fill="#fff" d="M898.01,1144.61v456.82c0,191.21-155.01,346.22-346.22,346.22h0v-558.19"/>
                        </g>
                        <g id="图层_5">
                          <path fill="#fff" d="M551.79,1308.87V666.88c0-191.21,155.01-346.22,346.22-346.22h0v757.97"/>
                        </g>
                        <g id="图层_4">
                          <path fill="#fff" d="M1871.18,582.7v1018.44c0,191.21-155.01,346.22-346.22,346.22h0V895.22"/>
                        </g>
                        <g id="图层_3">
                            <g>
                                <polygon fill="#fff" stroke-width="3"  points="105.56,1606.24 1048.57,845.17 1213.56,1173.23 2162.15,320.07 1976.53,842.87 1940.67,714.54
                                1146.1,1429.24 971.37,1029.98"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="line">
                    <svg xmlns="http://www.w3.org/2000/svg" width="466" height="241" viewBox="0 0 466 241">
                        <line class="line1" fill="none" stroke="rgba(255,255,255,.2)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="175" y1="85" x2="0" y2="0"></line>
                        <line class="line2" fill="none" stroke="rgba(255,255,255,.2)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="260" y1="100" x2="490" y2="30"></line>
                        <line class="line3" fill="none" stroke="rgba(255,255,255,.2)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="213" y1="135" x2="185" y2="243"></line>
                    </svg>
                </div>
                <div class="move-box move-1 a-fadein line-delay">
                    <span class="move-title" @click="onVisible(1)">{{ data.list1.title }}</span>
                    <div class="ball pulse" @click="onVisible(1)">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 2267.72 2267.72" style="enable-background:new 0 0 2267.72 2267.72;" xml:space="preserve">
                            <g id="图层_2">
                                <path fill="#fff" d="M898.01,1144.61v456.82c0,191.21-155.01,346.22-346.22,346.22h0v-558.19"/>
                            </g>
                          <g id="图层_5">
                              <path fill="#fff" d="M551.79,1308.87V666.88c0-191.21,155.01-346.22,346.22-346.22h0v757.97"/>
                            </g>
                          <g id="图层_4">
                              <path fill="#fff" d="M1871.18,582.7v1018.44c0,191.21-155.01,346.22-346.22,346.22h0V895.22"/>
                            </g>
                          <g id="图层_3">
                                <g>
                                    <polygon fill="#fff" stroke-width="3"  points="105.56,1606.24 1048.57,845.17 1213.56,1173.23 2162.15,320.07 1976.53,842.87 1940.67,714.54
                                    1146.1,1429.24 971.37,1029.98"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="move-box move-2 a-fadein line-delay">
                    <span class="move-title" @click="onVisible(2)">{{ data.list2.title }}</span>
                    <div class="ball pulse" @click="onVisible(2)">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 2267.72 2267.72" style="enable-background:new 0 0 2267.72 2267.72;" xml:space="preserve">
                                <g id="图层_2">
                                    <path fill="#fff" d="M898.01,1144.61v456.82c0,191.21-155.01,346.22-346.22,346.22h0v-558.19"/>
                                </g>
                          <g id="图层_5">
                                  <path fill="#fff" d="M551.79,1308.87V666.88c0-191.21,155.01-346.22,346.22-346.22h0v757.97"/>
                                </g>
                          <g id="图层_4">
                                  <path fill="#fff" d="M1871.18,582.7v1018.44c0,191.21-155.01,346.22-346.22,346.22h0V895.22"/>
                                </g>
                          <g id="图层_3">
                                    <g>
                                        <polygon fill="#fff" stroke-width="3"  points="105.56,1606.24 1048.57,845.17 1213.56,1173.23 2162.15,320.07 1976.53,842.87 1940.67,714.54
                                        1146.1,1429.24 971.37,1029.98"/>
                                    </g>
                                </g>
                            </svg>
                    </div>
                </div>
                <div class="move-box move-3 a-fadein line-delay">
                    <span class="move-title title-3" @click="onVisible(3)">{{ data.list3.title }}</span>
                    <div class="ball pulse" @click="onVisible(3)">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 2267.72 2267.72" style="enable-background:new 0 0 2267.72 2267.72;" xml:space="preserve">
                                <g id="图层_2">
                                    <path fill="#fff" d="M898.01,1144.61v456.82c0,191.21-155.01,346.22-346.22,346.22h0v-558.19"/>
                                </g>
                          <g id="图层_5">
                                  <path fill="#fff" d="M551.79,1308.87V666.88c0-191.21,155.01-346.22,346.22-346.22h0v757.97"/>
                                </g>
                          <g id="图层_4">
                                  <path fill="#fff" d="M1871.18,582.7v1018.44c0,191.21-155.01,346.22-346.22,346.22h0V895.22"/>
                                </g>
                          <g id="图层_3">
                                    <g>
                                        <polygon fill="#fff" stroke-width="3"  points="105.56,1606.24 1048.57,845.17 1213.56,1173.23 2162.15,320.07 1976.53,842.87 1940.67,714.54
                                        1146.1,1429.24 971.37,1029.98"/>
                                    </g>
                                </g>
                            </svg>
                    </div>
                </div>
            </div>

        </div>
        <!--e: right-->
        <div class="layer" v-if="visible || visible1" @click="onClose"></div>
    </div>
    <!--s: 弹出-->
    <div class="content-eject a-fadein" v-if="visible">
        <i class="close" @click="onClose"></i>
        <span class="eject-title">{{ data.title }}</span>
        <h1 class="eject-name">{{ listObj.title }}</h1>
        <div class="eject-list">
            <div class="box">
                <p class="strip" :key="key" v-for="(list,key) in listObj.list">{{ list }}</p>
            </div>
        </div>
    </div>
    <div class="content-big-eject a-fadein" v-if="visible1">
        <div class="eject-tab">
            <a href="javascript:" @click="onTabSwitch(1)" :class="bigStatus === 1 ? 'active':''">{{ data.list1.title }}</a>
            <a href="javascript:" @click="onTabSwitch(2)" :class="bigStatus === 2 ? 'active':''">{{ data.list2.title }}</a>
            <a href="javascript:" @click="onTabSwitch(3)" :class="bigStatus === 3 ? 'active':''">{{ data.list3.title }}</a>

            <i class="eject-close" @click="onBigClose"><img src="../../../assets/close-white.png"/></i>
        </div>
        <div class="eject-con">
            <div class="eject-li" :key="key" v-for="(listObj,key) in bigListObj.list">
                <div class="li-tab">{{ listObj.title }}</div>
                <div class="li-text">{{ listObj.text }}</div>
            </div>
        </div>
    </div>
    <!--e: 弹出-->
</div>
</template>

<script>
import Content from './content'
export default Content
</script>
<style lang="less">
@import "content";
</style>
