
/**
 * content
 */
import {mapState} from "vuex";
import { serviceData } from '@/service/enum'
const Content = {
    name:'content',
    computed: {
        ...mapState({
            status: state => state.service.status,  //获取状态service列表状态
        }),
    },
    data() {
        return {
            visible:false,
            visible1:false,
            data:{},
            listObj:[],
            bigStatus:0,
            bigListObj:[],
        }
    },
    created() {
        if(this.status == 1){
            this.data = serviceData[0]
        }
        if(this.status == 2){
            this.data = serviceData[1]
        }
    },
    methods: {
        onClose(){
            this.visible = false
            this.visible1 = false
        },
        onBigClose(){
            this.visible1 = false
        },
        onTabSwitch(n){
            this.bigStatus = n
            if(n === 1){
                this.bigListObj = this.data.list1
            }
            if(n === 2){
                this.bigListObj = this.data.list2
            }
            if(n === 3){
                this.bigListObj = this.data.list3
            }
        },
        onBreak(){
            this.$router.push('/')
            window.location.href = '#service'
        },
        onVisible(number){
            if(this.status == 1){
                this.visible1 = true
                this.bigStatus = number
                if(number === 1){
                    this.bigListObj = this.data.list1
                }
                if(number === 2){
                    this.bigListObj = this.data.list2
                }
                if(number === 3){
                    this.bigListObj = this.data.list3
                }
            }else{
                if(number === 1){
                    this.listObj = this.data.list1
                }
                if(number === 2){
                    this.listObj = this.data.list2
                }
                if(number === 3){
                    this.listObj = this.data.list3
                }
                this.visible = true
            }
        },
    }
}
export default Content
